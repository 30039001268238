import {useMemo, useState} from 'react';
import {useTranslation}    from 'foundations/i18n/i18n';
import {Icon}              from '@glimpse/glass';
import ComponentsImage     from 'features/home/components-image.png';
import AstroBlowhornImage  from 'foundations/brand/astro/astro-blowhorn.png';
import AstroWaitingImage   from 'foundations/brand/astro/astro-waiting.png';
import AstroPizzaImage     from 'foundations/brand/astro/astro-pizza.png';
import TeamsImage          from 'features/home/teams-image.png';
import ReposImage          from 'features/home/repos-image.png';
import ScaffoldsImage      from 'features/home/scaffolds-image.png';
import ScorecardsImage     from 'features/home/scorecards-image.png';
import InsightsImage       from 'features/home/insights-image.png';
import PipelinesImage      from 'features/home/pipelines-image.png';
import Image               from 'next/image';

export function ProductShowcase() {
    const [selected, setSelected] = useState<string>('components');
    const {t}                     = useTranslation();

    const products: {
        key: string,
        title: string,
        selectLabel: string,
        selectText: string,
        description?: string,
        bg: string,
        text: string,
        icon: string[],
        image: React.ReactNode
        features: {
            label: string,
            icon: string[]
        }[]
    }[] = useMemo(() => [
        {
            key:         'components',
            title:       t('Catalog'),
            selectLabel: t('Catalog'),
            selectText:  'text-sky-500',
            bg:          'bg-sky-500',
            text:        'text-white',
            icon:        ['far', 'server'],
            image:       <Image width={0} height={0} src={ComponentsImage.src} alt={''} className={'rounded w-auto h-auto lg:-rotate-2 -mt-1 border'}/>,
            features:    [
                {label: t('Components'), icon: ['far', 'boxes']},
                {label: t('Health'), icon: ['far', 'wave-pulse']},
                {label: t('Ownership'), icon: ['far', 'user-group-simple']},
                {label: t('Dependencies'), icon: ['far', 'arrows-cross']},
                {label: t('Properties'), icon: ['far', 'tags']},
                {label: t('Automation'), icon: ['far', 'gears']}
            ]
        },
        {
            key:         'scorecards',
            title:       t('Scorecards'),
            selectLabel: t('Scorecards'),
            selectText:  'text-emerald-500',
            bg:          'bg-emerald-500',
            text:        'text-white',
            icon:        ['far', 'list-check'],
            image:       <Image width={0} height={0} src={ScorecardsImage.src} alt={''} className={'rounded mx-auto max-h-60 w-auto lg:rotate-2 -mt-1 border'}/>,
            features:    [
                {label: t('Measure Health'), icon: ['far', 'heart-pulse']},
                {label: t('Enforce Standards'), icon: ['far', 'medal']},
                {label: t('Automate Compliance'), icon: ['far', 'shield-check']},
                {label: t('Drive Change'), icon: ['far', 'chart-line-up']}
            ]
        },
        {
            key:         'scaffolds',
            title:       t('Scaffolds'),
            selectLabel: t('Scaffolds'),
            selectText:  'text-orange-500',
            bg:          'bg-orange-500',
            text:        'text-white',
            icon:        ['far', 'box-open'],
            image:       <Image width={0} height={0} src={ScaffoldsImage.src} alt={''} className={'rounded w-auto lg:max-h-60 mx-auto lg:rotate-2 -mt-1 border'}/>,
            features:    [
                {label: t('Self Serve'), icon: ['far', 'users']},
                {label: t('GIT Templates'), icon: ['far', 'code-branch']},
                {label: t('Cookiecutter (Coming Soon)'), icon: ['far', 'cookie']}
            ]
        },
        {
            key:         'self-serve',
            title:       t('Self Service'),
            selectLabel: t('Self Service'),
            selectText:  'text-teal-500',
            bg:          'bg-teal-500',
            text:        'text-white',
            icon:        ['far', 'hand-pointer'],
            image:       <Image width={0} height={0} src={AstroPizzaImage.src} alt={''} className={'w-auto max-h-52 m-auto'}/>,
            features:    [
                {label: t('Automation'), icon: ['far', 'robot']},
                {label: t('JIT Permissions'), icon: ['far', 'lock']}
            ]
        },
        {
            key:         'code',
            title:       t('Code Management (Beta)'),
            selectLabel: t('Code'),
            selectText:  'text-blue-500',
            bg:          'bg-blue-500',
            text:        'text-white',
            icon:        ['far', 'code'],
            image:       <Image width={0} height={0} src={ReposImage.src} alt={''} className={'rounded w-full max-w-2xl h-auto lg:rotate-2 -mt-1 border'}/>,
            features:    [
                {label: t('Repositories'), icon: ['far', 'bug']},
                {label: t('Code Review'), icon: ['far', 'code-pull-request']}
            ]
        },
        {
            key:         'awareness',
            title:       t('Awareness (Coming soon)'),
            selectLabel: t('Awareness'),
            selectText:  'text-rose-500',
            bg:          'bg-rose-500',
            text:        'text-white',
            icon:        ['far', 'triangle-exclamation'],
            image:       <Image width={0} height={0} src={AstroBlowhornImage.src} alt={''} className={'w-auto max-h-52 m-auto'}/>,
            features:    [
                {label: t('Bug reports'), icon: ['far', 'bug']},
                {label: t('Incident Management'), icon: ['far', 'sensor-triangle-exclamation']},
                {label: t('Alerting'), icon: ['far', 'bell']}
            ]
        },
        {
            key:         'directory',
            title:       t('Directory'),
            selectLabel: t('Directory'),
            selectText:  'text-pink-500',
            bg:          'bg-pink-500',
            text:        'text-white',
            icon:        ['far', 'sitemap'],
            image:       <Image width={0} height={0} src={TeamsImage.src} alt={''} className={'rounded w-full max-w-2xl h-auto lg:rotate-2 -mt-1 border'}/>,
            features:    [
                {label: t('Teams'), icon: ['far', 'people-group']},
                {label: t('People'), icon: ['far', 'user-group-simple']},
                {label: t('On-call'), icon: ['far', 'phone']},
                {label: t('Ownership'), icon: ['far', 'list-tree']}
            ]
        },
        {
            key:         'pipelines',
            title:       t('Pipelines'),
            selectLabel: t('Pipelines'),
            selectText:  'text-emerald-500',
            bg:          'bg-emerald-500',
            text:        'text-white',
            icon:        ['far', 'arrows-repeat'],
            image:       <Image width={0} height={0} src={PipelinesImage.src} alt={''} className={'mt-4 rounded lg:-rotate-2 h-auto w-auto max-w-2xl m-auto'}/>,
            features:    [
                {label: t('Deployments'), icon: ['far', 'upload']},
                {label: t('Environments'), icon: ['far', 'clouds']},
                {label: t('Status'), icon: ['far', 'clock']}
            ]
        },
        {
            key:         'insights',
            title:       t('Insights'),
            selectLabel: t('Insights'),
            selectText:  'text-purple-500',
            bg:          'bg-purple-500',
            text:        'text-white',
            icon:        ['far', 'bolt'],
            image:       <Image width={0} height={0} src={InsightsImage.src} alt={''} className={'rounded w-auto max-w-2xl h-auto lg:rotate-2 -mt-1 mx-auto border'}/>,
            features:    [
                {label: t('Analytics & Suggestions'), icon: ['far', 'chart-simple']},
                {label: t('Glimpse Query Language'), icon: ['far', 'magnifying-glass']},
                {label: t('Dashboards (Coming Soon)'), icon: ['far', 'gauge']},
            ]
        }
    ], [t]);

    return (
        <div className={'site-width'}>
            <div className={`flex overflow-y-auto pt-1 mb-4 gap-1`}>
                {
                    products.map((product) => {
                        const active = product.key === selected;
                        return (
                            <div key={product.key} onClick={() => setSelected(product.key)} className={`block text-center border border-transparent hover:border-slate-200 flex-grow rounded-xl p-2 w-[150px] cursor-pointer transition hover:-translate-y-1 ${active ? product.bg : null}`}>
                                <Icon fa={product.icon} className={`mb-1 ${active ? product.text : product.selectText}`} fontSize={3}/>
                                <span className={`block ${active ? `${product.text} font-bold` : null}`}>{product.selectLabel}</span>
                            </div>
                        );
                    })
                }
            </div>
            <div>
                {
                    products.map(product => {
                        if (product.key !== selected) {
                            return null;
                        }

                        return (
                            <div key={product.key} className={`${product.bg} px-4 pt-2 rounded-lg grid grid-cols-1 lg:grid-cols-12 lg:gap-8`}>
                                <div className={'lg:col-span-8 hidden lg:block'}>
                                    {product.image}
                                </div>
                                <div className={`py-4 lg:col-span-4 ${product.text}`}>
                                    <h2 className={'font-black text-3xl text-inherit mb-4'}>{product.title}</h2>
                                    <div className={`grid mb-6 ${product.features.length > 4 ? 'grid-cols-2' : 'grid-cols-1'}`}>
                                        {
                                            product.features.map((feature) =>
                                                <div key={feature.label} className={'flex gap-1 items-center mb-1'}>
                                                    <Icon fa={feature.icon} className={`text-center w-2 bg-black bg-opacity-10 p-1 rounded ${product.text}`}/>
                                                    <span className={`font-bold opacity-70 ${product.text}`}>{feature.label}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div>
                                        <a href={'https://app.withglimpse.com'} className={'border-2 border-white rounded-3xl px-3 py-1 transition opacity-80 hover:opacity-100 inline-flex gap-1 items-center text-white'}>
                                            <span className={'text-white font-bold'}>{t('Explore')}</span>
                                            <Icon fa={['fas', 'fa-chevron-right']}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

function Product() {

}