'use client';

import {Icon}                        from '@glimpse/glass';
import React, {AnchorHTMLAttributes} from 'react';
import styles                        from 'foundations/button/button.module.css';
import classNames                    from 'classnames';

export function Button({
    children,
    variant = 'primary',
    href,
    target = '_self',
    onClick,
    loading = false
}: {
    children: React.ReactNode
    variant?: 'primary' | 'secondary' | 'primary-outline',
    href?: string,
    target?: AnchorHTMLAttributes<any>['target']
    onClick?: () => void,
    loading?: boolean
}) {
    const classname = classNames(styles.button, {
        [styles.secondary]:      variant === 'secondary',
        [styles.primary]:        variant === 'primary',
        [styles.primaryOutline]: variant === 'primary-outline'
    });

    return (
        <a className={classname} href={href} target={target} onClick={loading ? () => {
        } : onClick}>
            {
                loading &&
                <Icon fa={['fas', 'fa-spinner-third']} fontSize={1} className={'animate-spin p-0.5'}/>
            }
            {
                !loading &&
                <>
                    <span className={styles.text}>{children}</span>
                    <div className={styles.icon}>
                        <Icon fa={['fas', 'fa-chevron-right']} fontSize={0.5}/>
                    </div>
                </>
            }
        </a>
    );
}