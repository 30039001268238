import {useTranslation} from 'foundations/i18n/i18n';
import {Icon}           from '@glimpse/glass';
import Marquee from "react-fast-marquee";

export function ValuesAccentBand() {
    const {t} = useTranslation();
    return (
        <Marquee className={'min-w-fit py-2 bg-slate-900 border-box max-w-full overflow-hidden'} autoFill speed={30}>
            <Item color={'text-amber-500'} text={t('Increase Velocity')} icon={'rabbit-running'}/>
            <Item color={'text-emerald-500'} text={t('Control Cost')} icon={'dollar-sign'}/>
            <Item color={'text-purple-500'} text={t('See More Clearly')} icon={'cloud-sun'}/>
            <Item color={'text-sky-500'} text={t('Accelerate onboarding')} icon={'graduation-cap'}/>
            <Item color={'text-yellow-500'} text={t('Promote Quality')} icon={'stars'}/>
            <Item color={'text-purple-500'} text={t('Ensure Standards')} icon={'ballot-check'}/>
            <Item color={'text-emerald-500'} text={t('Have Happier Devs')} icon={'face-smile-hearts'}/>
            <Item color={'text-rose-500'} text={t('Continuously Improve')} icon={'arrows-rotate'}/>
        </Marquee>
    );
}

const Item = ({
    icon,
    text,
    color
}: {
    color: string,
    text: string,
    icon: string,
}) => {
    return (
        <div className={'nowrap  px-4 lg:px-6 whitespace-nowrap'}>
            <Icon fa={['fas', icon]} className={`mr-1.5 ${color}`} fontSize={3}/>
            <span className={'text-white font-medium text-lg nowrap'}>{text}</span>
        </div>
    );
};