'use client';

import {Button}           from 'foundations/button/button';
import {useTranslation}   from 'foundations/i18n/i18n';
import {Icon}             from '@glimpse/glass';
import {ProductShowcase}  from 'features/home/product-showcase/product-showcase';
import AstroRocketImage   from 'foundations/brand/astro/astro-rocket.png';
import {SupportedBy}      from 'features/shared/supported-by/supported-by';
import {ValuesAccentBand} from 'features/shared/value-accent-band/values-accent-band';
import Image              from 'next/image';
import OverviewImage      from 'features/home/overview-image.png';

export default function HomePage() {
    const {t} = useTranslation();
    return (
        <div>
            <div className={'px-2 max-w-[1800px] mx-auto pt-12'}>
                <h1 className={'text-4xl lg:text-6xl mb-4 font-black text-center'}>{t('Your Engineering Operating System')}</h1>
                <span className={'block md:text-2xl text-center mb-4 opacity-60'}>{t('The single platform to help developers manage their stack, code, and team')}</span>
                <div className={'mb-16 flex justify-center'}>
                    <Button href={'https://app.withglimpse.com/signup'}>{t('Get Started Free')}</Button>
                </div>
                <Image src={OverviewImage} alt={'Glimpse dashboard'} className={'max-w-full md:max-w-7xl object-cover object-top rounded-t-xl mx-auto border-4 border-opacity-10 block md:max-h-60 border-b-0'}/>
            </div>
            <div className={'mb-14'}>
                <ValuesAccentBand/>
            </div>
            <div className={'mb-20'}>
                <div className={'site-width'}>
                    <SupportedBy/>
                </div>
            </div>
            <div className={'site-width'}>
                <div className={'relative mb-20'}>
                    <img src={AstroRocketImage.src} className={'h-[200px] lg:h-[300px] lg:absolute m-auto'}/>
                    <h2 className={'block text-4xl lg:text-5xl text-center font-black mb-2'}>{t('Break through the grind.')}</h2>
                    <h2 className={'block text-4xl lg:text-5xl text-center font-black mb-6'}>{t('Soar through your day instead.')}</h2>
                    <span className={'text-lg opacity-80 max-w-2xl block text-center mx-auto mb-3'}>{t('It’s a struggle to juggle vast tech stacks, ever changing codebases, disjointed tooling and changing expectations — especially without having whole picture. That’s why we built Glimpse.')}</span>
                    <span className={'text-lg opacity-80 max-w-2xl block text-center mx-auto mb-3'}>{t('Glimpse gives you and your team a single place to work by bringing all your data under one roof. Save time, stress less.')}</span>
                </div>
            </div>
            <ProductShowcase/>
            <div className={'site-width pb-16 pt-16 px-4'}>
                <h2 className={'block text-5xl text-center font-black mb-16 max-w-xl m-auto'}>{t('Built with the foundations you expect')}</h2>
                <div className={'grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 lg:gap-y-12'}>
                    <div>
                        <Icon fa={['far', 'circle-check']} className={'mb-1 block text-g-secondary'} fontSize={4}/>
                        <span className={'font-bold block text-xl mb-2 text-g-secondary'}>{t('Get Started Quickly')}</span>
                        <span className={'text-slate-700'}>{t('Integrate with developer-friendly APIs or choose low-code or pre-built solutions.')}</span>
                    </div>
                    <div>
                        <Icon fa={['far', 'circle-check']} className={'mb-1 block text-g-secondary'} fontSize={4}/>
                        <span className={'font-bold block text-xl mb-2 text-g-secondary'}>{t('Integrate your favourite apps')}</span>
                        <span className={'text-slate-700'}>{t('Integrate Glimpse with your favourite tools, including Github & Jira.')}</span>
                    </div>
                    <div>
                        <Icon fa={['far', 'circle-check']} className={'mb-1 block text-g-secondary'} fontSize={4}/>
                        <span className={'font-bold block text-xl mb-2 text-g-secondary'}>{t('Great Human support')}</span>
                        <span className={'text-slate-700'}>{t('Talk to an amazing team of humans that are here to help you succeed')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}