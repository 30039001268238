import {useTranslation} from 'foundations/i18n/i18n';
import {Icon}           from '@glimpse/glass';
import Image            from 'next/image';
import NotionLogo       from 'features/shared/supported-by/notion-logo.png';
import MicrosoftLogo    from 'features/shared/supported-by/microsoft-logo.png';
import PosthogLogo    from 'features/shared/supported-by/posthog-logo.png';
import SavantLogo    from 'features/shared/supported-by/savant-logo.png';
import IntercomLogo    from 'features/shared/supported-by/intercom-logo.png';

export function SupportedBy() {
    const {t} = useTranslation();
    return (
        <div className={'text-center'}>
            <span className={'mx-auto block mb-4 opacity-40 text-slate-900'}>{t('Supported by')}</span>
            <div className={'flex justify-center flex-col md:flex-row gap-6 md:gap-12 items-center grayscale'}>
                <Image src={NotionLogo.src} height={0} width={0} className={'h-6 w-auto opacity-50'} alt={'Notion logo'}/>
                <Image src={MicrosoftLogo.src} height={0} width={0} className={'h-4 w-auto'} alt={'Microsoft logo'}/>
                <Image src={PosthogLogo.src} height={0} width={0} className={'h-4 w-auto opacity-50'} alt={'Posthog logo'}/>
                <Image src={SavantLogo.src} height={0} width={0} className={'h-4 w-auto opacity-50'} alt={'Savant logo'}/>
                <Image src={IntercomLogo.src} height={0} width={0} className={'h-4 w-auto opacity-90'} alt={'Intercom logo'}/>
            </div>
        </div>
    );
}

const Item = ({
    icon,
    text,
    color
}: {
    color: string,
    text: string,
    icon: string,
}) => {
    return (
        <div className={'nowrap flex-shrink-0'}>
            <Icon fa={['fas', icon]} className={`mr-1.5 ${color}`} fontSize={3}/>
            <span className={'text-white font-medium text-lg nowrap'}>{text}</span>
        </div>
    );
};